@import "../global/bootstrap_constants";

.map-simple {
    display: flex;
	width: 100%;
	height: 25rem;
	background-color: aqua;
    position: relative;

	@include media-breakpoint-up("md") {
        height: 30rem;
	}
	@include media-breakpoint-up("lg") {
        height: 35rem;
	}
	@include media-breakpoint-up("xl") {
        height: 38rem;
	}
	@include media-breakpoint-up("xxl") {
        height: 37.5rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

    .view-larger {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0, 0.55);
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: none;
        transition: .4s;

        span {
            color: $white;
            font-weight: bold;
        }
    }

    &:hover {
        .view-larger {
            display: flex;
        }
    }
}
