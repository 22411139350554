@import "../global/gap";
@import "../global/colors";

.contact-info {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    // @include flex_item_gap(gap);

    @include media-breakpoint-up("lg") {
        flex-flow: wrap;
        gap: 0;
        height: 100%;
    }

    &__item {
        display: flex;
        flex-flow: row;
        color: $white;
        gap: 1rem;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        transition: .4s;

        &:hover {
            background-color: $section_bkgr;
            color: $gold_primary;
        }

        @include media-breakpoint-up("lg") {
            flex: 100%;
            max-width: 100%;
            padding: 0 1rem;

            &:not(:last-child) {
                border-bottom: 1px dashed $gold_primary;
            }
        }
        @include media-breakpoint-up("xxl") {
            flex: 50%;
            max-width: 50%;
            flex-flow: column;
            justify-content: center;

            &:not(:last-child) {
                border-bottom: 0;
            }

            &:first-child {
                border-bottom: 1px dashed $gold_primary;
                border-right: 1px dashed $gold_primary;
            }

            &:last-child {
                border-top: 1px dashed $gold_primary;
                border-left: 1px dashed $gold_primary;
            }
        }

        &--icon {
            width: 100%;
            height: auto;
            max-width: 2rem;

            @include media-breakpoint-up("lg") {
                max-width: 3.5rem;
            }

            @include media-breakpoint-up("xxl") {
                max-width: 4rem;
            }
        }

        &--text {
            font-size: 1.2rem;

            @include media-breakpoint-up("xxl") {
                text-align: center;
            }
        }
    }
}