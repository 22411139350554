@import "../global/gap";
@import "../global/fonts";
@import "../global/colors";

.section {
	display: flex;
	flex-flow: column;
	@include section_gap(gap);
	scroll-margin-top: 10rem;

	@include media-breakpoint-up("md") {
		scroll-margin-top: 0rem;
	}

	&__intro {
		&--title {
			text-align: center;
			// font-family: map-get($map: $font-secondary, $key: "bold");
			font-size: 2rem;
			line-height: 3rem;
			@include text_gradient();
			
			@include media-breakpoint-up("lg") {
				font-size: 3rem;
				line-height: 5rem;
			}
			@include media-breakpoint-up("xl") {
				font-size: 3.5rem;
				line-height: 5rem;
			}
			@include media-breakpoint-up("xxl") {
				font-size: 3.5rem;
				line-height: 7rem;
			}
		}

		&--short-desc {
			text-align: center;
			font-size: 1.2rem;
			font-style: italic;
			color: $white;
		}
	}

	&__wrapper {
		&--content {
			margin: 0 auto;
			max-width: 1920px;
		}
	}
}
