@import "../global/gap";
@import "../global/colors";

footer {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include media-breakpoint-up("lg") {
        grid-template-columns: 1fr 3fr;
    }
}