@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import "../global/colors";
@import "../global/fonts";
@import "../global/buttons";

.hero {
	display: flex;
	flex-flow: column-reverse;
	height: 30rem;
	background-color: $section_bkgr;
	// background-color: $pink_lite;
	// background-color: $gray_dark;
	box-shadow: 1px 1px $gray_dark;

	@include media-breakpoint-up('lg') {
		flex-flow: row;
		height: 40rem;
	}
	@include media-breakpoint-up('xxl') {
		height: 50rem;
	}


	&__content {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		padding: $spacer calc($container-padding-x * 0.5);
		gap: 1rem;
		flex: 100%;
		max-width: 100%;
		padding: 1rem;
		
		@include media-breakpoint-up('lg') {
			// padding: 2.5rem 1.5rem 2.5rem 2.5rem;
			padding: 2.5rem;
			gap: 3rem;
			// flex: calc(100% / 2);
			// max-width: calc(100% / 2);
        }
        @include media-breakpoint-up('xxl') {
            // padding: 5rem 2rem 5rem 5rem;
			padding: 5rem;
			gap: 5rem;
			// flex: calc(100% / 3);
			// max-width: calc(100% / 3);
        }

		&--slogan {
			width: 100%;

			@include media-breakpoint-up('lg') {
				width: 75%;
			}
			@include media-breakpoint-up('xxl') {
				width: 50%;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		&--title {
			font-family: map-get($font-secondary, "regular");

			h1 {
				// font-style: italic;
				font-size: 2rem;
				line-height: 3rem;
				text-align: center;
				@include text_gradient();

				@include media-breakpoint-up('lg') {
					font-size: 3.5rem;
					line-height: 5rem;
					text-align: start;
				}

				@include media-breakpoint-up('xxl') {
					font-size: 3.5rem;
					line-height: 7rem;
				}
			}
		}

		&--buttons {
            font-family: map-get($font-primary, "medium");
			display: flex;
			flex-flow: column;
			gap: map-get($map: $spacers, $key: 2);

			@include media-breakpoint-up('lg') {
				align-items: flex-start;
			}

			a {
				width: 100%;
				@include button_styles();
				background-color: transparent;
				@include text_gradient();
				border-color: $gold_secondary !important;

				&:hover {
					@include text_gradient_hover();
					border-color: $gold_primary_hover !important;
				}

				@include media-breakpoint-up('lg') {
					width: auto;
				}

				&.btn-termin {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: map-get($map: $spacers, $key: 2);

					@include media-breakpoint-up('lg') {
						display: none;
					}
				}
			}
		}
	}

	&__media {
		flex: 1;
		max-width: none;

		@include media-breakpoint-up('lg') {
			flex: calc(100% * 2 / 3);
			max-width: calc(100% * 2 / 3);
		}

		&--figure {
			position: relative;
			width: 100%;
			height: 100%;
			background: url("../../../public/assets/img/nails93-header-02_v1.webp");
			background-position: center;
			background-size: cover;
			clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%); // https://css-tricks.com/create-diagonal-layouts-like-its-2020/
			display: flex;
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up('lg') {
				clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
			}
		}
	}
}
