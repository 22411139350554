/* Poppins */
@font-face {
	font-family: Poppins-Regular;
	src: url("../../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
	font-family: Poppins-Medium;
	src: url("../../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
	font-family: Poppins-Bold;
	src: url("../../fonts/Poppins/Poppins-Bold.ttf");
}

/* Lato */
@font-face {
	font-family: Lato-Regular;
	src: url("../../fonts/Lato/Lato-Regular.ttf");
}
@font-face {
	font-family: Lato-Bold;
	src: url("../../fonts/Lato/Lato-Bold.ttf");
}

$font-primary: (
	"regular": "Poppins-Regular",
	"medium": "Poppins-Medium",
	"bold": "Poppins-Bold"
);

$font-secondary: (
	"regular": "Lato-Regular",
	"bold": "Lato-Bold"
);

$font-sizes: (
	"xxs": .7rem,
	"xs": .8rem,
	"sm": .9rem,
	"md": 1rem,
	"lg": 1.25rem,
	"xl": 1.5rem,
	"xxl": 1.75rem,
);