@import "../global/gap";
@import "../global/colors";

.appointment-buttons {
    // background-color: $white;
    display: flex;
    flex-flow: column;
    @include flex_gap(gap);
    align-items: center;

    &__desc {
        font-size: 1.2rem;
        font-style: italic;
    }

    &__links {
        display: flex;
        flex-flow: row;
        align-items: center;
        // justify-content: space-around;
        width: 100%;

        .quick-links {
            flex: 50%;
            max-width: 50%;
            display: flex;
            flex-flow: column;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $white;
            padding: 1rem;
            transition: .4s;
            // border: 1px solid transparent;
            // border-radius: 1rem;
            // width: 10rem;

            &:hover {
                // background-color: $blue_primary;
                // color: $white;
                background-color: $section_bkgr;
            }

            &:not(:last-child) {
                border-right: 1px solid $white;
            }

            &__icon {
                max-width: 3.5rem;
                width: 100%;
                height: auto;

                @include media-breakpoint-up("lg") {
                    max-width: 4rem;
                }
            }
            &__text {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }
}