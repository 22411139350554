@import "../global/gap";

.appointment {
	@include section_gap(padding, true, false);
	color: $white;

	.appointment-details {
		display: grid;
		grid-template-columns: 1fr;

		@include media-breakpoint-up("lg") {
			grid-template-columns: 1fr 1fr;
		}

        &__with-appointment,
        &__without-appointment {
			display: flex;
			flex-flow: column;
			@include responsive_gap(padding);
			@include flex_gap(gap);
        }
	}
}
