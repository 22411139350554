@import './colors';

/* Custom theme/colors */
$primary: $pink_primary;
$secondary: $pink_secondary;
// $dark: black;
// $light: lightgray;
// $success: blue;
$info: #3b5998;
// $warning: yellow;
// $danger: orange;

@import 'bootstrap/scss/bootstrap';

/* Custom buttons */
.btn {
    border-radius: 2rem;
}