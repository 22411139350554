$pink_primary: #fc85b5;
$pink_secondary: #edb0c9;
$pink_lite: #ffd6e5;
$blue_primary: #3b5998;
$gray_light: #f5f5f5;
$gray_dark: #555555;
$near_black_lite: #27272b;
$near_black: #1a1a1d;
$gold_primary: #f7e788;
$gold_dark: #b1803c;
$gold_secondary: #e5b864;
$gold_alternative: #ebd57e;
$gold_primary_hover: #faf5d9;
$gold_dark_hover: #b09773;
$gold_secondary_hover: #e7d2ac;
$gold_alternative_hover: #e9e1be;
$white: #ffffff;

$section_bkgr: rgba(26, 26, 29, 0.8);

@mixin gradient_setup() {
	background-size: 100%;
	background-repeat: repeat;
    // -webkit-text-fill-color: transparent;
    // -moz-text-fill-color: transparent;
	background-image: linear-gradient(45deg, $gold_primary, $gold_dark, $gold_secondary, $gold_alternative, $gold_dark);
}

@mixin text_gradient() {
    @include gradient_setup();
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
	background-color: transparent;
    color: $gold_primary;
}

@mixin text_gradient_hover() {
    @include gradient_setup();
    background-clip: border-box;
    -webkit-text-fill-color: $near_black;
    -moz-text-fill-color: $near_black;
	background-color: $gold_primary;
    color: $near_black;
	// background-color: $gold_primary_hover;
	// background-image: linear-gradient(45deg, $gold_primary_hover, $gold_dark_hover, $gold_secondary_hover, $gold_alternative_hover, $gold_dark_hover);
}