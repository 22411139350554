@import "../global/gap";
@import "../global/colors";

.footer-menu {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: flex-end;
	@include flex_gap(gap);
	@include responsive_gap(padding);
	height: 100%;
	background-color: $section_bkgr;
    color: $gray_light;
	clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%);
	padding-top: 15% !important;
	padding-bottom: 1rem !important;

	@include media-breakpoint-up("lg") {
		clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        padding-top: 4rem !important;
		padding-left: 15% !important;
	}

	&__links {
		display: flex;
		flex-flow: column;
		@include flex_gap(gap);
		width: 100%;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up("lg") {
			flex-flow: row;
			align-items: flex-end;
			justify-content: space-between;
		}

		&--subscribe {
			flex: 100%;
			max-width: 100%;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            align-items: center;

            @include media-breakpoint-up("lg") {
                align-items: flex-start;
            }

            .subscribe-desc {
                font-size: 1.2rem;
            }
            .social-networks {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                gap: 0.2rem;
            }
		}

		&--legal {
			flex: 100%;
			max-width: 100%;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            align-items: center;

            @include media-breakpoint-up("lg") {
                align-items: flex-end;
            }

            .contact-infos {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                gap: 0.7rem;

                a {
                    img {
                        width: 3.35rem;
                        height: auto;
                    }
                }
            }

            .legal-infos {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;

                @include media-breakpoint-up("lg") {
                    justify-content: flex-end;
                }

                a {
                    color: $gray_light;
                    text-decoration: none;
                    font-size: 1.2rem;
                }
            }
		}
	}

    &__copyright {
        color: $gray_dark;
    }
}
