@import "../global/gap";
@import "../global/fonts";

.services {
	display: flex;
	flex-flow: column;
	@include section_gap(padding, true, false);
	@include section_gap(gap);
	// background-color: aquamarine;

	// &__intro {
	// 	&--title {
	// 		text-align: center;
	// 		// font-size: $h2-font-size;
	// 		font-family: map-get($map: $font-secondary, $key: "bold");
	// 	}

	// 	&--short-desc {
	// 		text-align: center;
	// 		font-size: 1rem;
	// 	}
	// }

	&__list {
		display: flex;
		flex-wrap: wrap;

		&--item {
			position: relative;
			flex: 100%;
			height: 15rem;

			@include media-breakpoint-up("md") {
				flex: 50%;
				height: 20rem;
			}
			@include media-breakpoint-up("lg") {
				height: 25rem;

				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					flex: calc(100% / 3);
				}
			}
			@include media-breakpoint-up("xl") {
				height: 30rem;
			}
			@include media-breakpoint-up("xxl") {
				height: 35rem;
			}

			&__img {
				width: 100%;
				height: 100%;
				// max-height: 20rem;
				object-fit: cover;
				object-position: center;
			}
			&__title {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgb(0, 0, 0, 0.35);
				transition: 0.4s;

				&:hover {
					background-color: rgb(0, 0, 0, 0.55);
				}

				& > h4 {
					color: $white;
					text-align: center;
					font-size: 1.75rem;
					line-height: 1.75rem;

					@include media-breakpoint-up("lg") {
						font-size: 2rem;
						line-height: 2rem;
					}
					@include media-breakpoint-up("xl") {
						font-size: 2.25rem;
						line-height: 2.25rem;
					}
					@include media-breakpoint-up("xxl") {
						font-size: 2.5rem;
						line-height: 2.5rem;
					}
				}
			}
		}
	}
}
