@import './fonts';

@mixin button_styles {
	font-size: map-get($map: $font-sizes, $key: "md");

	@include media-breakpoint-up("lg") {
		width: auto;
		font-size: map-get($map: $font-sizes, $key: "lg");
	}
}
