@import "../global/gap";

.contact {
	@include section_gap(padding, true, false);

	.contact-details {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		@include media-breakpoint-up("lg") {
			grid-template-columns: 1fr 1fr;
			gap: 0;
		}

		&__general {
			display: flex;
			flex-flow: column;
			@include responsive_gap(padding-left);
			@include responsive_gap(padding-right);
			@include flex_gap(gap);
		}
	}
}
