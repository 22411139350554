@import "../global/gap";
@import "../global/fonts";
@import "../global/colors";

.footer-info {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    @include flex_gap(gap);
    padding: $spacer calc($container-padding-x * 0.5);
    
    @include media-breakpoint-up('lg') {
        align-items: flex-start;
        padding: 2.5rem 1.5rem 2.5rem 2.5rem;
    }
    @include media-breakpoint-up('xxl') {
        padding: 5rem 2rem 5rem 5rem;
    }

    &__logo {
        width: 100%;
        height: 2.5rem;
        text-align: center;

        @include media-breakpoint-up("md") {
            height: 3.5rem;
        }

        @include media-breakpoint-up('lg') {
            text-align: left;
        }

        @include media-breakpoint-up("xxl") {
            height: 5rem;
        }

		img {
			width: auto;
			height: 100%;
		}
    }

    &__slogan {
        font-family: map-get($map: $font-secondary, $key: "regular");
        font-size: 1.5rem;
        font-style: italic;
        @include text_gradient();
    }
}