@import "../global/gap";
@import "../global/colors";

.price-list {
	@include section_gap(padding, true, false);

	.main-list {
		display: grid;
		grid-template-columns: 1fr;
		gap: 3rem;
		// @include section_gap(gap);

		@include media-breakpoint-up("sm") {
			gap: 3rem;
		}
		@include media-breakpoint-up("md") {
			gap: 4rem;
		}
		@include media-breakpoint-up("lg") {
			// gap: 1rem;
			column-gap: 1rem;
			row-gap: 5rem;
			grid-template-columns: 1fr 1fr;
		}
		@include media-breakpoint-up("xl") {
			// gap: 1.5rem;
			column-gap: 1rem;
		}
		@include media-breakpoint-up("xxl") {
			// gap: 1.5rem;
			grid-template-columns: 1fr 1fr 1fr;
		}

		&__category {
			height: 100%;
			margin: 0 0.75rem;

			@include media-breakpoint-up("lg") {
				margin: 0 1rem;
			}

			& > * {
				@include responsive_gap(padding-left);
				@include responsive_gap(padding-right);
			}

			&--title {
				position: relative;
				padding-bottom: 0.5rem;

				img {
					position: absolute;
					width: 100%;
					height: auto;
					max-width: 3.5rem;
					top: -1rem;
				}

				h3 {
					margin: 0;
					font-size: 1.75rem;
					line-height: 1.4rem;
					padding-left: 4rem;
					color: $gold_alternative;
				}

				@include media-breakpoint-up("sm") {
				}
				@include media-breakpoint-up("md") {
					img {
						max-width: 5rem;
						top: -1.5rem;
					}
					h3 {
						padding-left: 6rem;
					}
				}
				@include media-breakpoint-up("lg") {
				}
				@include media-breakpoint-up("xl") {
					img {
						max-width: 6rem;
						top: -1.8rem;
					}
					h3 {
						padding-left: 7rem;
					}
				}
				@include media-breakpoint-up("xxl") {
				}
			}

			&--content {
				height: max-content;
				background-color: $section_bkgr;
				border: 1px dashed $gold_alternative;
				color: $white;
				@include responsive_gap(padding-top, true);
				@include responsive_gap(padding-bottom, true);

				@include media-breakpoint-up("lg") {
					height: 100%;
				}

				.price-table {
					display: flex;
					flex-wrap: wrap;
					gap: 1.5rem;

					&__item {
						flex: 100%;
						max-width: 100%;
						display: flex;
						flex-flow: column;
						gap: 1.2rem;
						// @include flex_gap(margin-top);

						// @include media-breakpoint-up("lg") {
						// 	flex: 50%;
						// 	max-width: 50%;

						// 	&:nth-child(2n + 1) {
						// 		@include flex_item_gap(padding-right);
						// 	}

						// 	&:nth-child(2n) {
						// 		@include flex_item_gap(padding-left);
						// 	}

						// 	&:nth-of-type(-n + 2) {
						// 		margin-top: 0;
						// 	}
						// }

						& > h4 {
							display: flex;
							flex-flow: row;
							align-items: flex-start;
							justify-content: space-between;
							margin: 0;

							& > .name {
								text-decoration: underline;
								color: $gold_alternative;
							}

							// & > .main-price {
							// 	font-weight: bold;
							// }
						}

						&--desc {
							font-style: italic;
						}

						&--details {
							.the-service {
								display: flex;
								flex-flow: row;
								align-items: flex-start;
								justify-content: space-between;
								gap: 1rem;
								transition: .3s;
								// @include flex_item_gap(gap);

								&:nth-child(2n) {
									background-color: $near_black_lite;
								}

								&__name {
									text-align: left;
								}

								&__badge {
									margin-left: 0.25rem;
									margin-bottom: 0.25rem;
								}

								&__price {
									// font-weight: bold;
									font-style: italic;
									text-align: right;
								}

								&__second-price {
									// font-weight: bold;
									font-style: italic;
									text-align: right;
								}

								&:hover {
									background-color: $gold_alternative_hover;
									color: $near_black !important;
								}
							}

							.the-service-3-col {
								display: grid;
								grid-template-columns: 7.6fr 2.2fr 2.2fr;
							}
						}

						&.large-col {
							flex: 100% !important;
							max-width: 100% !important;
							padding-left: 0 !important;
							padding-right: 0 !important;

							@include media-breakpoint-up("lg") {
								.the-service {
									gap: 0;
									
									&__name {
										flex: 50%;
										max-width: 50%;
										justify-self: flex-start;
										@include flex_item_gap(padding-right);
									}
									&__price {
										flex: 50%;
										max-width: 50%;
										justify-self: flex-start;
										@include flex_item_gap(padding-left);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
