@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../global/fonts";
@import "../global/buttons";
@import "../global/colors";

#main_navigation {
	// background-color: $white;
	box-shadow: 1px 1px $gray_dark;
	background: url('../../../public/assets/img/bkgr_01.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	background-attachment: fixed;

	.navbar-toggler {
		&:focus {
			box-shadow: none;
		}
	}

	.navbar-brand {
		padding-left: 0;
		width: 60%;

		@include media-breakpoint-up("sm") {
			width: auto;
		}

		@include media-breakpoint-up("xxl") {
			padding-left: 5rem;
		}

		img {
			width: 100%;
			height: auto;
			max-height: 2.5rem;

			@include media-breakpoint-up("md") {
				max-height: 3.5rem;
			}

			@include media-breakpoint-up("xxl") {
				max-height: 5rem;
			}
		}
	}

	.navbar-toggler {
		color: $gold_primary;
		border-color: $gold_primary;

		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28247, 231, 136, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
		}
	}

	#main_menu {
		padding-right: 0;
		flex-grow: 0;
		font-family: map-get($font-primary, "medium");

		@include media-breakpoint-up("xxl") {
			padding-right: 5rem;
		}

		.navbar-nav {
			gap: 0;

			@include media-breakpoint-up("lg") {
				gap: map-get($spacers, 4);
			}

			& > a {
				text-align: center;
                font-size: map-get($map: $font-sizes, $key: "md");
				@include text_gradient();
                
                @include media-breakpoint-up("lg") {
                    font-size: map-get($map: $font-sizes, $key: "lg");
                }

                &:not(:last-child) {
                    border-bottom: 1px dashed $gray-300;

                    @include media-breakpoint-up("lg") {
                        border-bottom: 0;
                    }
                }

				.nav-icon {
                    display: inline-block;
					opacity: 0.5;
					font-size: 1rem;

                    @include media-breakpoint-up("lg") {
                        display: none;
                    }

                    &.nav-icon-front {
                        transform: scaleX(-1);
                        margin-right: 0.5rem;
                    }

                    &.nav-icon-back {
                        margin-left: 0.5rem;
                    }
				}
			}
		}
	}

	.btn-termin {
		font-family: map-get($font-primary, "medium");
        @include button_styles();
		transition: .4s;
		border: 1px solid $gold_secondary;
		@include text_gradient();

		&:hover {
			border: 1px solid $gold_primary_hover;
			@include text_gradient_hover();
		}
	}

	.btn-desktop {
		display: none;

		@include media-breakpoint-up("lg") {
			display: block;
		}
	}

	.btn-mobile {
		display: block;
		margin-top: map-get($map: $spacers, $key: 2);

		@include media-breakpoint-up("lg") {
			display: none;
		}

		a {
			width: 100%;
		}
	}
}
