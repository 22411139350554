@import "./bootstrap_constants";

@mixin section_gap($type, $has_top_gap: true, $has_bottom_gap: true) {
	@if $type == gap {
		gap: calc($spacer * 2);

		@include media-breakpoint-up("sm") {
			gap: calc($spacer * 2);
		}

		@include media-breakpoint-up("md") {
			gap: calc($spacer * 2.5);
		}

		@include media-breakpoint-up("lg") {
			gap: calc($spacer * 3);
		}

		@include media-breakpoint-up("xl") {
			gap: calc($spacer * 3.5);
		}

		@include media-breakpoint-up("xxl") {
			gap: calc($spacer * 3.5);
		}
	} @else {
		$top_multiplier: 0;
		@if $has_top_gap == true {
			$top_multiplier: 1;
		}
		$bottom_multiplier: 0;
		@if $has_bottom_gap == true {
			$bottom_multiplier: 1;
		}

		#{$type}-top: calc($spacer * 5 * $top_multiplier);
		#{$type}-bottom: calc($spacer * 5 * $bottom_multiplier);

		@include media-breakpoint-up("lg") {
			#{$type}-top: calc($spacer * 10 * $top_multiplier);
			#{$type}-bottom: calc($spacer * 10 * $bottom_multiplier);
		}

		@include media-breakpoint-up("xxl") {
			#{$type}-top: calc($spacer * 10 * $top_multiplier);
			#{$type}-bottom: calc($spacer * 10 * $bottom_multiplier);
		}
	}
}

@mixin responsive_gap($type, $extra: false) {
	$extra_gap: 0;
	@if $extra == true {
		$extra_gap: 1;
	}

	#{$type}: $spacer * 0.75 + $extra_gap;

	@include media-breakpoint-up("sm") {
		#{$type}: $spacer * 0.75 + $extra_gap;
	}

	@include media-breakpoint-up("md") {
		#{$type}: $spacer * 0.75 + $extra_gap;
	}

	@include media-breakpoint-up("lg") {
		#{$type}: $spacer * 1.5 + $extra_gap;
	}

	@include media-breakpoint-up("xl") {
		#{$type}: $spacer * 2 + $extra_gap;
	}

	@include media-breakpoint-up("xxl") {
		#{$type}: $spacer * 2.5 + $extra_gap;
	}
}

@mixin flex_gap($type) {
	#{$type}: calc($spacer * 1);

	@include media-breakpoint-up("sm") {
		#{$type}: calc($spacer * 1);
	}

	@include media-breakpoint-up("md") {
		#{$type}: calc($spacer * 1.5);
	}

	@include media-breakpoint-up("lg") {
		#{$type}: calc($spacer * 2.5);
	}

	@include media-breakpoint-up("xl") {
		#{$type}: calc($spacer * 3);
	}

	// @include media-breakpoint-up("xxl") {
	// 	#{$type}: calc($spacer * 3);
	// }
}

@mixin flex_item_gap($type) {
	#{$type}: calc($spacer * 1 / 2);

	@include media-breakpoint-up("sm") {
		#{$type}: calc($spacer * 1 / 2);
	}

	@include media-breakpoint-up("md") {
		#{$type}: calc($spacer * 1.5 / 2);
	}

	@include media-breakpoint-up("lg") {
		#{$type}: calc($spacer * 2.5 / 2);
	}

	@include media-breakpoint-up("xl") {
		#{$type}: calc($spacer * 3 / 2);
	}

	// @include media-breakpoint-up("xxl") {
	// 	#{$type}: calc($spacer * 3 / 2);
	// }
}