@import "../global/gap";

.openinghours {
	display: flex;
	flex-flow: column;
	@include flex_gap(gap);
	align-items: center;

	@include media-breakpoint-up("lg") {
		align-items: flex-start;
	}

	&__desc {
		font-size: 1.2rem;
		font-style: italic;
	}

	&__timetable {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: flex-start;

		&--icon {
			@include flex_item_gap(padding-right);

			img {
				max-width: 3.5rem;
				width: 100%;
				height: auto;

				@include media-breakpoint-up("lg") {
					max-width: 4rem;
				}
			}
		}

		&--time {
			display: inline-table;
			border-collapse: separate;
			border-spacing: 1rem 0;

			.timetable-item {
				&__day,
				&__time {
                    vertical-align: top;
					font-size: 1rem;

                    @include media-breakpoint-up("md") {
                        font-size: 1.2rem;
                    }
				}
				&__time {
					font-weight: 500;
				}
			}
		}
	}
}
