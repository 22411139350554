/* FONTS */
@import './components/global/fonts';
html, body {
	font-family: map-get($font-primary, "regular");
	hyphens: auto;
}
#root {
	background: url('../public/assets/img/bkgr_01.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}

/* CUSTOM BOOTSTRAP */
@import './components/global/custom_bootstrap';